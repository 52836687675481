.calendar {
  .fc-col-header-cell-cushion {
    text-decoration: none;
  }
  .fc-event-time {
    display: none;
  }
  .fc-daygrid-event {
    font-size: 11px;
    cursor: pointer;
    background: #121a44;
    color: white;
  }
  .fc-daygrid-day-top {
    flex-direction: row;
  }
  .fc-daygrid-day-number {
    text-decoration: none;
    margin-top: 5px;
    margin-left: 5px;
  }
  .fc-daygrid-day {
    background: white;
  }
  .fc-day-today {
    background: white !important;
    .fc-daygrid-day-number {
      background: #121a44;
      border-radius: 50%;
      padding: 8px;
      color: white;
    }
  }
  .fc-button-primary {
    background: transparent !important;
    color: #121a44;
    border: none !important;
    &:hover {
      background: transparent !important;
      color: #121a44 !important;
      cursor: pointer;
    }
    &:active {
      border: none !important;
      background: none;
    }
  }
  .fc-toolbar-title {
    text-transform: capitalize;
  }
  .fc-day-past {
    background: #fbf8f8;
  }
  .fc-day-other {
    background: #eff2f3;
  }
  .fc-theme-standard th {
    border: none;
    overflow: hidden;
  }
  .fc-theme-standard td {
    border-color: #eaeaf5;
    height: 150px;
  }
  .fc-daygrid-event-dot {
    display: none;
  }
  .fc-event-title {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 10px);
    padding: 5px;
    text-wrap: initial;
  }
  .fc-scrollgrid-sync-table {
    height: auto !important;
    width: 100% !important;
  }
  .fc-scroller-liquid-absolute {
    position: relative;
  }
  .fc-view-harness {
    height: auto !important;
    .fc-daygrid {
      position: relative;
    }
  }
  .fc-daygrid-day-events {
    padding: 10px;
  }
  .fc-col-header-cell-cushion {
    font-weight: 400;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  table {
    border: none !important;
  }
}
